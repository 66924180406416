import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { AlertProps, ComponentSeverity, Link } from '@bl/components/dist/types';
import { BlFrontendBaseComponent } from './bl-frontend-base.component';

@Component({
  selector: 'bl-frontend-alert',
  template: '<div></div>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class BlFrontendAlertComponent extends BlFrontendBaseComponent<AlertProps> {
  @Input() header?: AlertProps['header'];
  @Input() content: AlertProps['content'];
  @Input() severity?: ComponentSeverity = 'neutral';
  @Input() useUnsafeHtml?: AlertProps['useUnsafeHtml'] = false;
  @Input() dismissable?: AlertProps['dismissable'] = false;
  @Input() listItems?: Link[];
  @Input() link?: Link;

  constructor(protected override ref: ElementRef) {
    super(ref);
  }

  protected override getComponentName(): string {
    return 'Alert';
  }

  protected override getProps(): AlertProps {
    return {
      header: this.header,
      content: this.content,
      severity: this.severity,
      useUnsafeHtml: this.useUnsafeHtml,
      dismissable: this.dismissable,
      listItems: this.listItems,
      link: this.link,
    };
  }
}
