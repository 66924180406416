import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { BaseEffects } from '../core/base.effects';
import { ListActions } from '../list/list.actions';
import { NavigationPages } from '../navigation/navigation.selectors';
import { AppState } from '../appState';
import { CollaborationPackageActions } from './collaboration-packages.actions';
import { CloudCompanyService } from '@app/core/services/cloud-company.service';
import { ToastActions } from '../toast/toast.actions';
import { environment } from 'src/environments/environment';
import { ClientSelectors } from '../clients/clients.selectors';
import { OnboardingService } from '@app/core/services/onboarding.service';
import { ClientService } from '@app/core/services/clients.service';
import { CoreActions } from '../actions';
import { toClientTypeTransformer } from '../transformers/transformers';
import { ClientActionType, CollaborationPackageItem } from '../types/collaboration-package.types';
import { CollaborationPackageSelectors } from './collaboration-packages.selectors';
import { ClientActions } from '../clients/clients.actions';

@Injectable()
export class CollaborationPackageEffects extends BaseEffects {
  loadClientsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.load),
      this.whenCurrentUrl(NavigationPages.collaborationPackages),
      map(({ filter: listFilter }) => ListActions.load({ filter: listFilter })),
    ),
  );

  loadCloudCompanyListItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.load),
      this.whenCurrentUrl(NavigationPages.collaborationPackages),
      switchMap(({ params }) =>
        this.cloudCompanyService.getCollaborationCloudCompanies(params).pipe(
          mergeMap(({ meta, data }) => [
            ListActions.loadSucceeded({ items: data }),
            CollaborationPackageActions.setMeta({ meta }),
          ]),
          this.takeUntilNavigationStart(),
          catchError(() => of(ListActions.loadFailed({ error: { message: '' } }))),
        ),
      ),
    ),
  );

  loadEmailForClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.loadEmailForClient),
      concatLatestFrom(({ clientId }) => this.store.select(ClientSelectors.getClientById(clientId))),
      switchMap(([{ clientId, cloudApiKey }, client]) => {
        if (client?.email) {
          return of(CollaborationPackageActions.loadEmailForClientSucceeded({ email: client.email }));
        }

        return this.cloudCompanyService.getCloudCompanyEmail({ clientId, cloudApiKey }).pipe(
          map((email) => CollaborationPackageActions.loadEmailForClientSucceeded({ email })),
          catchError((error: unknown) => of(CollaborationPackageActions.loadEmailForClientFailed({ error }))),
        );
      }),
    ),
  );

  loadClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.loadClient),
      switchMap(({ clientId }) => {
        if (!clientId) {
          return of(CoreActions.noAction());
        }

        return this.getClientById(clientId).pipe(
          map((client) => CollaborationPackageActions.loadClientSucceeded({ client })),
          catchError((error: unknown) => of(CollaborationPackageActions.loadClientFailed({ error }))),
        );
      }),
    ),
  );

  // -- ONBOARDING --

  onboardExistsingClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.onboardExistingClient),
      switchMap(({ clientId, email }) =>
        this.onboardingService.getOnboardingKey(clientId, { client: { email }, financialInformation: null }).pipe(
          map((key) => CollaborationPackageActions.onboardExistingClientSucceeded({ key })),
          catchError((error: unknown) => of(CollaborationPackageActions.onboardExistingClientFailed({ error }))),
        ),
      ),
    ),
  );

  onboardNonExistsingClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.onboardNonExistingClient),
      switchMap(({ cloudApiKey, email }) =>
        this.onboardingService.getOnboardingKeyForNonExistingClient({ cloudApiKey, email }).pipe(
          map((key) => CollaborationPackageActions.onboardNonExistingClientSucceeded({ key })),
          catchError((error: unknown) => of(CollaborationPackageActions.onboardNonExistingClientFailed({ error }))),
        ),
      ),
    ),
  );

  openOnboardingInNewTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CollaborationPackageActions.onboardExistingClientSucceeded,
          CollaborationPackageActions.onboardNonExistingClientSucceeded,
        ),
        tap(({ key }) => {
          this.openOnboardingInNewTab(key);
        }),
      ),
    { dispatch: false },
  );

  // -- ONBOARDING WITH MINI COLLABORATION PACKAGE --

  onboardWithMiniCollaborationPackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.onboardWithMiniCollaborationPackage),
      switchMap(({ cloudApiKey, name, socialSecurityNumber }) =>
        this.onboardingService
          .onboardCompanyWithMiniCollaborationPackage({ cloudApiKey, name, socialSecurityNumber })
          .pipe(
            mergeMap((data) => [
              CollaborationPackageActions.onboardWithMiniCollaborationPackageSucceeded({ name }),
              ListActions.updateItemInListSucceeded({
                item: data,
                callback: (persistedItem, items) =>
                  // eslint-disable-next-line max-nested-callbacks
                  items.map((item) => {
                    if (item.cloudApiKey !== persistedItem.publicKey) {
                      return item;
                    }

                    // Update current item with the new subscription information
                    const { modules, integrations, subscriptionTypeId } = persistedItem;
                    return {
                      ...item,
                      subscription: { ...item.subscription, modules, integrations, subscriptionTypeId },
                    } as CollaborationPackageItem;
                  }),
              }),
            ]),
            catchError((error: unknown) =>
              of(CollaborationPackageActions.onboardWithMiniCollaborationPackageFailed({ error })),
            ),
          ),
      ),
    ),
  );

  onboardMiniCollaborationPackageSucceededMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.onboardWithMiniCollaborationPackageSucceeded),
      map(({ name }) =>
        ToastActions.showInfoMessage({
          summary: 'Mini paketet har lagts till!',
          detail: `Aktiveringen av Mini paketet på företaget ${name} har slutförts!`,
        }),
      ),
    ),
  );

  // -- ARCHIVE CLOUD DATABASE --

  archiveCloudDatabase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.archiveCloudDatabase),
      switchMap((payload) =>
        this.archiveCloudCompany(payload).pipe(
          map(() =>
            CollaborationPackageActions.archiveCloudDatabaseSucceeded({
              cloudApiKey: payload.cloudApiKey,
              name: payload.name,
              clientAction: payload.clientAction,
            }),
          ),
          catchError((error: unknown) => of(CollaborationPackageActions.archiveCloudDatabaseFailed({ error }))),
        ),
      ),
    ),
  );

  archiveCloudDatabaseSucceededMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.archiveCloudDatabaseSucceeded),
      map(({ name }) =>
        ToastActions.showInfoMessage({
          summary: 'Databasen är arkiverad!',
          detail: `Arkiverning av ${name} har slutförts!`,
        }),
      ),
    ),
  );

  // -- DELETE CLOUD DATABASE --

  deleteCloudDatabase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.deleteCloudDatabase),
      switchMap(({ cloudApiKey, name, clientAction }) =>
        this.cloudCompanyService.deleteCloudCompany(cloudApiKey).pipe(
          map(() => CollaborationPackageActions.deleteCloudDatabaseSucceeded({ cloudApiKey, name, clientAction })),
          catchError((error: unknown) => of(CollaborationPackageActions.deleteCloudDatabaseFailed({ error }))),
        ),
      ),
    ),
  );

  deleteCloudDatabaseSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollaborationPackageActions.deleteCloudDatabaseSucceeded),
      map(({ name }) =>
        ToastActions.showInfoMessage({
          summary: 'Databasen är borttagen!',
          detail: `Borttagningen av ${name} har slutförts!`,
        }),
      ),
    ),
  );

  // Removes the row from the list when archived
  removeCloudDatabaseFromListOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollaborationPackageActions.archiveCloudDatabaseSucceeded,
        CollaborationPackageActions.deleteCloudDatabaseSucceeded,
      ),
      map(({ cloudApiKey }) =>
        ListActions.deleteItemInListSucceeded({
          item: null,
          predicate: (item: CollaborationPackageItem) => item.cloudApiKey !== cloudApiKey,
        }),
      ),
    ),
  );

  // Archives the client in Byråstöd
  archivedClientWhenSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollaborationPackageActions.archiveCloudDatabaseSucceeded,
        CollaborationPackageActions.deleteCloudDatabaseSucceeded,
      ),
      filter(({ clientAction }) => clientAction === ClientActionType.ARCHIVE),
      concatLatestFrom(() => this.store.select(CollaborationPackageSelectors.selectClient)),
      filter(([, client]) => Boolean(client)),
      map(([, client]) =>
        ClientActions.archiveDeletableClient({
          client: { ...client, archived: true },
          predicate: null,
        }),
      ),
    ),
  );

  // Delete the client in Byråstöd
  deleteClientWhenSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollaborationPackageActions.archiveCloudDatabaseSucceeded,
        CollaborationPackageActions.deleteCloudDatabaseSucceeded,
      ),
      filter(({ clientAction }) => clientAction === ClientActionType.DELETE),
      concatLatestFrom(() => this.store.select(CollaborationPackageSelectors.selectClient)),
      filter(([, client]) => Boolean(client?.deleteable)),
      map(([, client]) =>
        ClientActions.deleteClient({
          client,
          predicate: null,
        }),
      ),
    ),
  );

  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor, max-params
  constructor(
    actions$: Actions,
    store: Store<AppState>,
    private cloudCompanyService: CloudCompanyService,
    private onboardingService: OnboardingService,
    private clientService: ClientService,
  ) {
    super(actions$, store);
  }

  private openOnboardingInNewTab(key: string): void {
    window.open(`${environment.blappWeb}/onboarding?key=${key}`, '_blank');
  }

  private getClientById(clientId: number) {
    return this.clientService.getClientById(clientId).pipe(map((client) => toClientTypeTransformer.transform(client)));
  }

  private archiveCloudCompany(
    payload: ReturnType<typeof CollaborationPackageActions.archiveCloudDatabase>,
  ): Observable<void> {
    const { cloudApiKey, years } = payload;
    return this.cloudCompanyService.archiveCloudCompany(cloudApiKey, years);
  }
}
