import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BLSvelteComponent, createBlSvelteComponent } from '@bl/components/dist/wrappers';

@Component({
  selector: 'bl-frontend-base',
  template: '',
  standalone: true,
  imports: [],
})
export abstract class BlFrontendBaseComponent<
  P extends Record<string, unknown>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  E extends Record<string, unknown> = {},
> implements OnInit, OnDestroy, OnChanges
{
  protected instance!: BLSvelteComponent<P, E>;

  // eslint-disable-next-line no-useless-constructor
  constructor(protected ref: ElementRef) {
    this.checkOverrides();
  }

  ngOnInit(): void {
    this.instance = createBlSvelteComponent<P, E>({
      componentName: this.getComponentName(),
      target: this.ref.nativeElement,
      props: this.getProps(),
    });

    this.onInitialized();
  }

  ngOnDestroy(): void {
    this.instance?.$destroy();
    this.onDestroyed();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateInstanceProps(changes);
    this.onChanges(changes);
  }

  protected abstract getComponentName(): string;

  protected abstract getProps(): P;

  protected onInitialized = () => {};

  protected onDestroyed = () => {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onChanges = (_changes: SimpleChanges) => {};

  private updateInstanceProps(changes: SimpleChanges): void {
    if (!this.instance) {
      return;
    }

    const updatedProps = this.getUpdatedProps(changes);

    if (Object.keys(updatedProps).length > 0) {
      this.instance.$set({ ...updatedProps });
    }
  }

  private getUpdatedProps(changes: SimpleChanges): P {
    return Object.keys(changes).reduce((acc, propName) => {
      const change = changes[propName];
      if (change.currentValue !== change.previousValue) {
        acc[propName as keyof P] = change.currentValue as P[keyof P];
      }
      return acc;
    }, {} as P);
  }

  private checkOverrides(): void {
    const props = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    const hasOnInit = this.checkNgOnInitOverride(props);
    const hasOnDestroy = this.checkNgOnDestroyOverride(props);
    const hasOnChanges = this.checkNgOnChangesOverride(props);

    if (hasOnInit || hasOnDestroy || hasOnChanges) {
      throw new Error(
        'ngOnInit/ngOnDestroy/ngOnChanges should not be implemented in derived classes of BlFrontendBaseComponent, override onInitialized, onDestroyed or onChanges instead!',
      );
    }
  }

  private checkNgOnInitOverride = (props: string[]) =>
    props.includes('ngOnInit') && typeof this.ngOnInit === 'function';

  private checkNgOnDestroyOverride = (props: string[]) =>
    props.includes('ngOnDestroy') && typeof this.ngOnDestroy === 'function';

  private checkNgOnChangesOverride = (props: string[]) =>
    props.includes('ngOnChanges') && typeof this.ngOnChanges === 'function';
}
