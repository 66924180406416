import { RadioButtonProps } from '@bl/components';
import {
  ActionType,
  ClientActionType,
  CollaborationPackageItem,
} from '@app/core/state/types/collaboration-package.types';

const getBoldText = (text: string): string => `<span class="font-semibold">${text}</span>`;

const getOnboardRadioItem = (): RadioButtonProps => ({
  name: 'onboard',
  value: ActionType.ONBOARD,
  text: 'Välj samarbetspaket',
  useUnsafeHtml: true,
  description: `Välj mellan något av de nya paketen ${getBoldText('Pro')}, ${getBoldText('Essential')}, ${getBoldText(
    'Go',
  )} eller ${getBoldText('Mini')}.`,
});

const getOnboardMiniRadioItem = (): RadioButtonProps => ({
  name: 'onboardWithMini',
  value: ActionType.ONBOARD_WITH_MINI,
  text: 'Välj paketet Mini - kunden saknar E-postadress',
  useUnsafeHtml: true,
  description: `Med det här valet kan du <em>inte</em> samarbeta med kunden i app.bjornlunden.se. Eftersom företaget saknar E-postadress har kunden <em>inte</em> tillgång till webb och app-tjänsten.`,
});

const getArchiveRadioItem = (subscriptionTypeId: number | null, archivedTo: string | null): RadioButtonProps => {
  const notArchivedDescription =
    'Företaget och all tillhörande data lagras kvar hos oss under den tid du behöver. Arkiveringen är kostnadsfri och du kan när som helst välja att återaktivera ditt företag till en kostnad av 690 kr enligt nuvarande prislista.';
  const archivedWithBlappDescription = `Företaget och all tillhörande data är arkiverad till: ${archivedTo}. Du kan när som helst välja att återaktivera ditt företag till en kostnad av 690 kr enligt nuvarande prislista. Detta gör du under fliken Inställningar i Bjorn Lunden webb och app på app.bjornlunden.se.`;
  const archivedWithoutBlappDescription = `Företaget och all tillhörande data är arkiverad till: ${archivedTo}. Du kan när som helst välja att återaktivera ditt företag till en kostnad av 690 kr enligt nuvarande prislista. Kontakta oss via supportforum för att få hjälp med återaktiveringen.`;

  let description = notArchivedDescription;
  if (archivedTo) {
    description = subscriptionTypeId ? archivedWithBlappDescription : archivedWithoutBlappDescription;
  }

  return {
    name: 'archive',
    value: ActionType.ARCHIVE,
    text: 'Arkivera företagsdatabas',
    description,
    useUnsafeHtml: true,
    disabled: !!archivedTo,
  };
};

const getDeleteRadioItem = (): RadioButtonProps => ({
  name: 'delete',
  value: ActionType.DELETE,
  text: 'Ta bort företagsdatabas',
  description: 'Ditt företag tas bort och all tillhörande data raderas utan möjlighet till återställning.',
});

// eslint-disable-next-line complexity
export const initializeActionRadioButtons = (config: CollaborationPackageItem): RadioButtonProps[] => {
  const { subscription, archivedTo, canUseOnboarding, canUseSimpleOnboarding } = config;
  const { subscriptionTypeId } = subscription || {};
  const formattedArchivedTo = archivedTo ? new Date(archivedTo).toLocaleDateString() : null;
  const radioButtons = [getArchiveRadioItem(subscriptionTypeId, formattedArchivedTo), getDeleteRadioItem()];

  // Adds the onboard to just "mini" package option if the cloud company doesn't have a blapp package or has whitelisted packages
  if (canUseSimpleOnboarding) {
    radioButtons.unshift(getOnboardMiniRadioItem());
  }

  // Adds the onboard option if the cloud company doesn't have a blapp package or
  // subscription type is old firm package
  if (canUseOnboarding) {
    radioButtons.unshift(getOnboardRadioItem());
  }

  // mark the first non disabled item as checked as default
  radioButtons.filter((rb) => !rb.disabled)[0].checked = true;
  return radioButtons;
};

const getSkipClientRadioItem = (): RadioButtonProps => ({
  name: 'skip',
  checked: true,
  value: ClientActionType.SKIP,
  text: 'Nej, jag vill lämna klienten som den är',
});

const getArchiveClientRadioItem = (): RadioButtonProps => ({
  name: 'archive',
  value: ClientActionType.ARCHIVE,
  text: 'Arkivera klienten i Byråstöd',
});

const getDeleteClientRadioItem = (deleteable: boolean): RadioButtonProps => ({
  name: 'delete',
  value: ClientActionType.DELETE,
  text: 'Ta bort klienten från Byråstöd',
  description: deleteable ? '' : 'Klienten har sparade aktiviteter och kan inte tas bort.',
  disabled: !deleteable,
});

// radio buttons for action on client in Byråstöd
export const initializeClientActionRadioButtons = (deleteable: boolean): RadioButtonProps[] => [
  getSkipClientRadioItem(),
  getArchiveClientRadioItem(),
  getDeleteClientRadioItem(deleteable),
];
